<template>
  <div class="flex justify-between items-center flex-1 mt-5">
    <div class="flex flex-1 items-center">
      <ImageResize :src="card.asset.img_front" type="player_item" />
      <div class="flex ml-2-4 flex-1">
        <div class="flex justify-between">
          <div class="font-bold sm:text-sm14">{{card.asset.name}}</div>
        </div>
        <div class="flex flex-col justify-between items-end ml-auto">
          <div class="text-right font-semibold sm:text-sm14">{{numberFormat(!isSelling ? card.estimate.received : card.estimate.spent, 6)}}</div>
          <div class="text-right font-medium opacity-60 leading-none sm:text-sm14">
            =&nbsp;${{numberFormat(!isSelling ? card.estimate.spent : $big(card.estimate.received).minus(card.estimate.fee))}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageResize } from '@/components/misc';

export default {
  name: 'PlayerItem',
  components: {
    ImageResize,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    isSelling: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
img {
  height: 49px;
  width: 49px;
  object-fit: contain;
}
</style>
