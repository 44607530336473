import { render, staticRenderFns } from "./PlayerItem.vue?vue&type=template&id=5f666bd8&scoped=true&"
import script from "./PlayerItem.vue?vue&type=script&lang=js&"
export * from "./PlayerItem.vue?vue&type=script&lang=js&"
import style0 from "./PlayerItem.vue?vue&type=style&index=0&id=5f666bd8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f666bd8",
  null
  
)

export default component.exports