<template>
  <div :class="`${!isMobileDevice ? 'player-container' : 'player-container-mobile'}`">
    <div class="flex justify-center">
      <ImageResize class="h-50 mx-auto only-xl:h-80 object-contain" :src="player.img" type="player_modal" />
    </div>
    <div :class="`${!isMobileDevice ? 'buy-form' : 'buy-form-mobile'} flex flex-col flex-1 px-11-7 pb-7-8 rounded-24 xl:rounded-b-none md:px-4`">
      <div class="text-center text-xl24 font-bold">{{$t('player.buy_player_collection')}}</div>

      <div class="flex flex-col mt-7-5">
        <div class="flex justify-between items-center">
          <div class="text-xl30 font-bold sm:text-xl20">{{$t('player.you_buy')}}</div>
          <div class="w-38">
            <DollarInput :setDollar="amountUsd" @input="setAmountUsd" customClasses="v8" />
          </div>
        </div>
        <div class="flex justify-between items-center font-semibold mt-5">
          <div class="opacity-20">{{$t('player.available_balance')}}</div>
          ${{numberFormat(userBalance)}}
        </div>
      </div>

      <div class="flex mt-7-5">
        <div
          @click="selectAmount('5')"
          :class="`selector ${Number(amountUsd) === 5 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          $5
        </div>
        <div
          @click="selectAmount('25')"
          :class="`selector ml-2-2 ${Number(amountUsd) === 25 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          $25
        </div>
        <div
          @click="selectAmount('50')"
          :class="`selector ml-2-2 ${Number(amountUsd) === 50 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          $50
        </div>
        <div
          @click="selectAmount('100')"
          :class="`selector ml-2-2 ${Number(amountUsd) === 100 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          $100
        </div>
      </div>

      <div class="flex justify-between items-center mt-7-5">
        <div class="text-xl30 font-bold sm:text-xl20">{{$t('player.you_receive')}}</div>
      </div>

      <PlayerCardsList
        class="mt-7-8"
        :player="player"
        :amountUsd="amountUsd"
        :cards="cards"
      />
      
      <div class="flex flex-col mt-5">
        <div class="flex justify-between items-center font-semibold mt-5">
          <div>{{$t('player.trade_fee')}}</div>
          ${{numberFormat(amountUsdFee)}}
        </div>
      </div>

      <div class="mt-auto">
        <ButtonV2
          @onClick="confirmPurchase"
          :inactive="isLoading || !amountUsd || amountUsd < 0.01"
          :label="$t('player.buy_player_collection')"
          testId="btn-but-collection"
          class="mt-10"
          wide
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { debounce } from 'vue-debounce';

  import PlayerCardsList from '@/components/player/PlayerCardsList';
  import { ImageResize } from '@/components/misc';
  import ButtonV2 from '@/stories/misc/ButtonV2';
  import DollarInput from '@/components/misc/DollarInput';
  import PlayerBuyDistribution from '@/graphql/queries/PlayerBuyDistribution.gql';
  import BUY_PLAYER from '@/graphql/mutations/BuyPlayer.gql';

  export default {
    name: 'BuyPlayer',
    components: {
      PlayerCardsList,
      ImageResize,
      ButtonV2,
      DollarInput,
    },
    data() {
      return {
        amountUsd: '',
        amountUsdFee: 0,
        buyDistributionId: '',
        cards: this.initialCards,
        isLoading: false,
      };
    },

    computed: {
      userBalance() {
        return this.$store.state.api['current_user'] && this.$store.state.api['current_user']['usd_balance'] ? this.$store.state.api['current_user']['usd_balance']['amount'] : 0;
      },
    },

    async mounted() {
      this.debouncer = debounce(() => {
        this.playerBuyDistribution();
      }, 300);

      const player = this.$store.state.api['player'];

      await this.$store.dispatch('events/track', {
        event: 'PLAYER_CHECKOUT_VIEWED',
        variables: {
          id: player.id,
          id_hr: player.id_hr,
          sport: player.sport ? player.sport.id_hr : '',
          team: player.team ? player.team.name : '',
          total_value: player.total_value,
          asset_count: player.asset_count,
          change_24h: player.change_24h,
          change_24h_pct: player.change_24h_pct,
          transaction_type: 'buy',
        },
      });

      this.$root.$on('confirm-buy-player', () => {
        this.purchase();
      });


      this.$root.$on('trade__add-funds', () => {
        this.showModal('PaymentInterface', { isDeposit: true });
      });
    },

    beforeDestroy() {
      this.$root.$off('confirm-buy-player');
      this.$root.$off('trade__add-funds');
    },

    props: {
      player: {
        type: Object,
        required: true,
      },
      initialCards: {
        type: Array,
        required: true,
      },
    },
    methods: {
    ...mapActions('user', ['requiresKycComplete']),

      selectAmount(amount) {
        this.amountUsd = amount;
      },

      setAmountUsd(value) {
        this.amountUsd = value;
        this.isLoading = true;
        this.debouncer();
      },

      async playerBuyDistribution() {
        try {
          this.isLoading = true;

          if(!this.amountUsd || this.amountUsd <= 0) {
            return;
          }

          const response = await this.$apollo.query({
            query: PlayerBuyDistribution,
            variables: {
              player_id_hr: this.$route.params['id_hr'], 
              amount_usd: this.amountUsd,
            },
            fetchPolicy: 'no-cache',
          });

          const buy_distribution = response.data.player_buy_distribution;

          this.cards = buy_distribution.assets;
          this.buyDistributionId = buy_distribution.id;
          this.amountUsdFee = Number(buy_distribution.amount_usd_fee);
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      },

      async confirmPurchase() {
        try {
          await this.requiresKycComplete();

          if (this.$big(this.amountUsd).gt(this.userBalance)) {
            return this.$store.commit('ui/showPrompt', {
              eventName: 'trade__add-funds',
              title: this.$t('trade.insufficient_balance'),
              message: this.$t('trade.please_make_sure_you_have'),
              customConfirm: this.$t('wallet.add_funds'),
            });
          }

          this.$store.commit('ui/showPrompt', {
            eventName: 'confirm-buy-player',
            title: this.$t('player.confirm_transaction'),
            message: this.$t('player.confirm_buy_player', { 
              amount: this.amountUsd, 
              player: this.player.name
            })
          });
        } catch (err) {
          await this.$store.dispatch('ui/showError', err);
        }
      },

      async purchase() {
        this.isLoading = true;

        try {
          const player = this.$store.state.api['player'];

          await this.$store.dispatch('events/track', {
            event: 'PLAYER_CHECKOUT_SUBMITTED',
            variables: {
              id: player.id,
              id_hr: player.id_hr,
              sport: player.sport ? player.sport.id_hr : '',
              team: player.team ? player.team.name : '',
              total_value: player.total_value,
              asset_count: player.asset_count,
              change_24h: player.change_24h,
              change_24h_pct: player.change_24h_pct,
              amount: this.amountUsd,
              transaction_type: 'buy',
            },
          });

          await this.$apollo.mutate({
            mutation: BUY_PLAYER,
            variables: {
              player_id_hr: this.$route.params['id_hr'], 
              amount_usd: this.amountUsd,
              buy_distribution_id: this.buyDistributionId,
            }
          });

          this.showModal('BuyPlayerConfirmation', {
            player: this.player,
            amountUsd: this.amountUsd,
            amountUsdFee: this.amountUsdFee,
            cards: this.cards,
            customBackground: 'bg-background-primary',
          });
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style scoped>
.player-container {
  @apply pt-11-7 px-11-7 -mx-4 min-w-sm;
  margin-bottom: -1.12rem;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 20px;
  height: min(calc(100vh - 25px), 910px);
}
.player-container-mobile {
  @apply flex flex-1 flex-col pt-7 px-7 mt-auto;
  margin-bottom: -1.12rem;
  height: min(calc(100vh - 25px), 910px);
}
.buy-form {
  @apply bg-background-primary -mx-11-8 pt-7-8;
}
.buy-form-mobile {
  @apply bg-background-primary -mx-7 pt-4;
}
.selector {
  @apply flex justify-center items-center flex-1 font-semibold border border-border py-1 cursor-pointer;
  border-radius: 10px;
}
</style>
