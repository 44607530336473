<template>
  <div class="relative" v-click-outside="closeDropdown">
    <div @click="toggleDropdown" class="list-container">
      <div class="flex justify-between items-center flex-1">
        <div class="flex items-center">
          <ImageResize :src="player.img" :alt="player.name" type="player_cards_list" />
          <div class="flex flex-col ml-2-4">
            <div class="text-n-xl font-bold sm:text-n-lg">{{player.name}}</div>
            <div class="text-sm14 font-medium opacity-60 sm:text-sm11">{{cards.length}} {{$t('player.cards')}}</div>
          </div>
        </div>
        <div :class="` ${isOpen ? 'hidden': 'flex xl:hidden'}`">
          <ButtonV2 v-if="!isLoading"
            :label="$t('player.see_details')"
            testId="btn-see_details"
            size="medium"
            version="secondary"
          />
          <Loading v-else :smallLoading="true" />
        </div>
        <IconTriangle :class="`default-transition w-5 h-13px fill-current text-text-body opacity-20 ${isOpen ? 'rotate-180': 'hidden xl:flex'}`" />
      </div>
    </div>

    <div v-if="isOpen" class="cards-list-container" :style="{maxHeight}">
      <PlayerItem
        v-for="(n, index) in cards.length"
        v-bind:key="index"
        :isSelling="isSelling"
        :card="cards[index]"
      />
    </div>
  </div>
</template>

<script>
import IconTriangle from '@/assets/icons/triangle.svg';
import ButtonV2 from '@/stories/misc/ButtonV2';
import PlayerItem from '@/components/player/PlayerItem';
import { ImageResize, Loading } from '@/components/misc';

export default {
  name: 'PlayerCardsList',
  components: {
    IconTriangle,
    ButtonV2,
    Loading,
    PlayerItem,
    ImageResize,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
    };
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    amountUsd: {
      type: String,
    },
    amountPct: {
      type: String,
    },
    receiveAmount: {
      type: Number,
    },
    cards: {
      type: Array,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '395px',
    },
    isSelling: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async toggleDropdown() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        const player = this.$store.state.api['player'];

        await this.$store.dispatch('events/track', {
          event: 'PLAYER_CHECKOUT_ASSET_BREAKDOWN_VIEWED',
          variables: {
            id: player.id,
            id_hr: player.id_hr,
            sport: this.lodashGet(player, 'sport.id_hr', ''),
            team: this.lodashGet(player, 'team.name', ''),
            total_value: player.total_value,
            asset_count: player.asset_count,
            change_24h: player.change_24h,
            change_24h_pct: player.change_24h_pct,
            amount: this.isSelling ? this.receiveAmount : this.amountUsd || 0,
            transaction_type: this.isSelling ? 'sell' : 'buy',
          },
        });
      }
    },
    closeDropdown() {
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>
.list-container {
  @apply flex relative cursor-pointer bg-background-primary p-5 border border-border;
  border-radius: 15px;
  z-index: 31;
}
.cards-list-container {
  @apply absolute w-full bg-background-primary px-5 pt-7 pb-5 left-0 border border-border;
  top: calc(100% - 25px);
  overflow-y: auto;
  box-shadow: 0px 3px 10px #00000033;
  border-radius: 15px;
  z-index: 30;
}
img {
  @apply bg-background-primary border border-border;
  height: 49px;
  width: 49px;
  border-radius: 50%;
  object-fit: cover;
}
::-webkit-scrollbar-track {
    margin-top: 15px;
    margin-bottom: 12px;
}
</style>
