
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Estimate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Estimate"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"not_spent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"received"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"spent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"average_price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"asset_out"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":99}};
    doc.loc.source = {"body":"fragment Estimate on Estimate {\n  not_spent\n  received\n  spent\n  fee\n  average_price\n  asset_out\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
