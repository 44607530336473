<template>
  <div class="relative">
    <input 
      placeholder="$0" 
      v-model="dollar" 
      type="string" 
      data-lpignore="true" 
      data-testid="input-dollar"
      :class="`${customClasses} ${isDisabled ? 'cursor-not-allowed' : ''}`" 
      :disabled="isDisabled" 
      v-focus="focus"
    />
    <div v-if="maxValue" class="absolute flex h-full items-center top-0 bottom-0 right-0 mr-3 text-sm10 text-text-positive font-semibold">
      <div v-if="!isDisabled" @click="setMaxAmount" class="cursor-pointer">
        {{ $t('trade.max') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DollarInput',

  data () {
    return {
      dollar: '',
    };
  },

  props: {
    customClasses: String,
    isDisabled: Boolean,
    maxValue: String,
    initialValue: { type: String, default: '' },
    setDollar: String,
    focus: { type: Boolean, default: false },
  },

  directives: {
    focus: {
      inserted: function (el, value) {
        if (value) {el.focus();}
      }
    }
  },

  methods: {
    setMaxAmount() {
      this.dollar = this.$big(this.maxValue).toFixed(2);
    },
  },

  mounted() {
    this.dollar = this.initialValue;
  },

  watch: {
    dollar(newValue, oldValue) {
      const match = /^\$?(?=[1-9])?\d*\.?(\d{1,2})?$/.test(newValue);

      if (newValue && !newValue.includes('$')) {
        this.dollar = `$${newValue}`;
      }

      if (!match) {
        return this.dollar = oldValue;
      }

      this.$emit('input', this.dollar.replace('$', ''));
    },
    setDollar() {
      this.dollar = this.setDollar;
    }
  }
};
</script>

<style scoped>

</style>